footer {
  position: relative;
  height: 60px;
  text-align: center;

  &.floating {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .copyright {
    position: absolute;
    width: 100%;
    color: var(--text-secondary);
    font-size: 0.875rem;
    font-family: var(--font-primary);
    bottom: 0;

    .fineprint {
      font-size: 0.6875rem;

      a {
        margin-left: -1px;
        border-bottom: 1px solid var(--text-secondary);
        padding-right: 1px;
        padding-left: 1px;
        color: var(--text-secondary);
        text-decoration: none;
        outline: none;

        &:focus {
          border-radius: 1px;
          box-shadow: 0 0 0 1px var(--text-secondary);
        }
      }
    }

    @media (max-width: 414px) {
      font-size: 0.75rem;
    }
  }
}
