.miner-container {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 120px);

  .miner {
    flex: 1;
    margin: 8px 16px 16px;
    padding: 16px 32px;
    max-width: 1024px;
    font-family: var(--font-primary);

    .signup-container {
      margin: auto;
      border: 1px solid var(--border-color);
      border-radius: 4px;
      background-color: var(--bg-secondary);
      padding: 16px 32px;
      max-width: 520px;
      font-family: var(--font-primary);
    }
  }
}
