.navigation-items {
  margin: 0;
  padding: 0;
  height: 100%;

  .desktop-only {
    margin-top: 10px;
  }

  .background {
    margin: 16px 0 16px 20px;
  }

  @media (min-width: 920px) {
    display: flex;

    .mobile-only {
      display: none;
    }
  }

  @media (max-width: 919px) {
    .desktop-only {
      display: none;
    }

    .background {
      position: absolute;
      top: 0;
      left: 16px;
      margin-left: 0;
    }
  }
}
