.infobox {
  margin-bottom: 24px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--bg-secondary);
  padding: 12px 24px;
  font-size: 0.875rem;

  p {
    margin-bottom: 0;

    &:first-of-type {
      margin-top: 0;
    }

    &.social {
      text-align: center;

      a {
        margin: -2px 0.5rem;
        border-radius: 1px;
        padding: 2px;

        &:hover {
          opacity: 0.5;
        }

        &:focus {
          box-shadow: 0 0 0 2px var(--text-primary);
        }
      }

      img {
        width: 20px;
        height: 20px;
        filter: var(--invert-color);
      }
    }
  }

  pre {
    overflow: auto;
  }

  pre,
  :not(pre) > code {
    border-radius: 4px;
    background: var(--bg-primary);
    padding: 3.2px 6.4px;
    font-size: 0.8125rem;
  }

  :not(pre) > code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  .option {
    color: var(--code-option);
  }

  .value {
    color: var(--code-value);
  }

  a {
    text-decoration: none;
    outline: none;
  }

  :not(p.social) > a {
    margin-right: -1px;
    margin-left: -1px;
    border-bottom: 2px solid transparent;
    padding-right: 1px;
    padding-left: 1px;
    color: var(--orange-primary);
    font-weight: bold;

    &:hover {
      border-color: var(--orange-primary);
    }

    &:focus {
      box-shadow: 0 0 0 2px var(--orange-primary);
      border-radius: 1px;
    }
  }
}
