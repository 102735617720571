@import 'reset';
@import 'variables';

* {
  transition: border-color 0.5s, background-color 0.5s, opacity 0.8s;
}

html {
  scroll-behavior: smooth;
}

body {
  color: var(--text-primary);
  background-color: var(--bg-primary);
  line-height: 1.5;
}

input {
  color: var(--text-primary);
}

hr {
  border: 0;
  border-top: 1px solid var(--border-color);
}

.react-select__option {
  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

#root.fouc {
  .fouc-container * {
    visibility: hidden;
  }
}

/* Custom scrollbar on Firefox */
* {
  scrollbar-color: var(--border-color) var(--bg-secondary);
}

/* Custom scrollbar on Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 14px;
}

*::-webkit-scrollbar-track {
  background: var(--bg-secondary);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--border-color);
}
