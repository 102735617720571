.navigation-item {
  font-family: var(--font-primary);
  font-size: 0.875rem;
  letter-spacing: var(--wide-spacing);

  a {
    display: block;
    margin-top: 2px;
    border-bottom: 4px solid transparent;
    padding: 18px 10px;
    width: 100%;
    height: 96%;
    color: var(--text-primary);
    text-decoration: none;
    outline: none;

    &:hover,
    &:active,
    &.active {
      border-color: var(--orange-primary);
      color: var(--text-primary);
      font-size: 0.864rem;
      font-weight: 600;
    }

    &:focus {
      border-radius: 3px;
      box-shadow: 0 0 0 2px var(--orange-primary);
    }

    @media (max-width: 919px) {
      padding: 9px 5px;
    }
  }
}
