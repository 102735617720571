.toolbar {
  display: flex;
  justify-content: space-between;
  position: fixed;
  height: 60px;
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--bg-secondary);
  padding: 0 40px;
  z-index: 3;
  box-shadow: 0 4px 4px -4px var(--shadow);

  nav {
    height: 100%;
  }

  .logo-toolbar {
    height: 40%;
    margin-right: 20px;
  }

  @media (max-width: 919px) {
    .logo-toolbar {
      margin-right: 0;
    }

    .desktop-only {
      display: none;
    }
  }
}
